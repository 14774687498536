import React from "react"
import { Container } from "reactstrap"
import { Navbar } from "./navbar"

export const Header = ({ pageName, text }) => {
  return (
    <Container fluid className={`header--container ${pageName}`} d>
      <Container className="header--content">
        <Navbar pageName={pageName} />
        <h1 className="header--content__text">{text}</h1>
      </Container>
    </Container>
  )
}
