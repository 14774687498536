import React from "react"
import { Col, Container, Row } from "reactstrap"
import { Link } from "gatsby"

export const Footer = () => {
  return (
    <Container fluid className="footer__container">
      <Container className="footer__container__content">
        <Row>
          <Col
            sm={12}
            lg={4}
            className="footer__container__col d-none d-lg-flex flex-column"
          >
            <Link to="/" className="font-size-24">
              Strona główna
            </Link>
            <Link to="/offer" className="font-size-24">
              Oferta
            </Link>
            <Link to="/price" className="font-size-24">
              Cennik
            </Link>
            <Link to="/womans" className="font-size-24">
              Dla kobiet
            </Link>
            <Link to="/contact" className="font-size-24">
              Kontakt
            </Link>
          </Col>
          <Col sm={4} className="footer__container__col flex-l">
            <div className="font-size-24">
              <img src="/images/pin.svg" width="32px" />
              Ul. Harenda 15F 34-500 Zakopane
            </div>
            <div className="font-size-24">
              <img src="/images/phone.svg" width="32px" />
              508 173 097
            </div>
            <div className="font-size-24">
              <img src="/images/mail.svg" width="32px" />
              ankakulach@gmail.com
            </div>
          </Col>
          <Col sm={4} className="footer__container__col">
            <img
              className="m-2 d-none d-lg-block"
              src="/images/blackLogo.svg"
            />
            <div className="font-size-24">© Copyright - Reha-Help</div>
            <div className="font-size-24">Projekt i realizacja: Comtru.art</div>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
