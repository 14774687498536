import * as React from "react"

import Layout from "../components/layout"
import {Header} from "../components/header";
import {Footer} from "../components/footer";
import {PriceList} from "../components/priceList";

const Price = () => (
  <Layout>
    <Header pageName="price" text="Cennik"/>
    <PriceList/>
    <Footer/>
  </Layout>
)

export default Price
