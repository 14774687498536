import React, { useEffect, useState } from "react"
import { Col, Container, Row, Table } from "reactstrap"

export const PriceList = () => {
  const [width, setWidth] = useState()
  console.log(width)

  const isDesktop = width >= 992

  useEffect(() => {
    setWidth(window.innerWidth)
  }, [])

  return (
    <Container className="pricelist--container">
      <Row>
        <Col sm={1} className="text-right">
          <img src="/images/dots.svg" />
        </Col>
        <Col sm={12} lg={10}>
          {isDesktop ? (
            <Table bordered className="pricelist--table">
              <thead>
                <tr>
                  <th>Rodzaj zabiegu</th>
                  <th>Pojedyńczy zabieg</th>
                  <th>Pakiet pięciu zabiegów</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Fizjoterapia
                    <br />
                    (około 50 min)
                  </td>
                  <td>120PLN</td>
                  <td>550PLN*</td>
                </tr>
                <tr>
                  <td>
                    Masaż relaksacyjny <br />
                    (60 min)
                  </td>
                  <td>160PLN</td>
                  <td>720PLN*</td>
                </tr>
                <tr>
                  <td>
                    Masaż relaksacyjny <br />
                    (90 min)
                  </td>
                  <td>190PLN</td>
                  <td>900PLN*</td>
                </tr>
                <tr>
                  <td>
                    Modelowanie twarzy <br />
                    (90 min)
                  </td>
                  <td>160PLN</td>
                  <td>720PLN*</td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <Table bordered className="pricelist--table">
              <thead>
                <tr>
                  <th>Pojedyńczy zabieg</th>
                  <th>Pakiet pięciu zabiegów</th>
                </tr>
                <tr>
                  <th colSpan={2}>Fizjoterapia (około 50min)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>120PLN</td>
                  <td>550PLN*</td>
                </tr>
                <tr>
                  <th colSpan={2}>Masaż relaksacyjny (60min)</th>
                </tr>
                <tr>
                  <td>160PLN</td>
                  <td>720PLN*</td>
                </tr>
                <tr>
                  <th colSpan={2}>Masaż relaksacyjny (90min)</th>
                </tr>
                <tr>
                  <td>190PLN</td>
                  <td>900PLN*</td>
                </tr>
                <tr>
                  <th colSpan={2}>Modelowanie twarzy (90min)</th>
                </tr>
                <tr>
                  <td>160PLN</td>
                  <td>720PLN*</td>
                </tr>
              </tbody>
            </Table>
          )}
          <p className="font-style-italic font-size-24">
            *przy zakupie pięciu zabiegów Voucher podarunkowy o wartości 100zł
            dla bliskiej osoby gratis. Ważność pakietu 6 miesięcy od daty
            zakupu.
          </p>
        </Col>
        <Col sm={1}>
          <img src="/images/dots.svg" />
        </Col>
      </Row>
    </Container>
  )
}
